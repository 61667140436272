<script lang="ts" setup>
import { FacebookOutlined, GoogleOutlined } from '@ant-design/icons-vue'
import { defineAsyncComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { callFunc } from '../../realm/data/call'
import { getRealm } from '~/realm/setup'
import type { UserType } from '~/models/user'
import { UserModel } from '~/models/user'
import { dataSaveSingle } from '~/realm/data/save'
import { useAclStore } from '~/store/acl'
import { siteTeamIds } from '~/shared/site'
import { dataFetchSingleWhere } from '~/realm/data/get'
import { OutletModel } from '~/models/outlet'
import ConfirmationDialog from '~/components/ConfirmationDialog.vue'

const props = defineProps({
  initialTab: {
    type: String,
    default: 'signup',
  },
})
const LoginComponent = defineAsyncComponent(() => import('./login-comp.vue'))
const userModel = new UserModel()
const outletModel = new OutletModel()
const $acl = useAclStore()
const router = useRouter()
const $route = useRoute()
// console.log('route', route, router)
const name = ref('')
const email = ref('')
const pass = ref('')
const agreeTerm = ref(false)
const loading = ref(false)
const error = ref('')
const tab = ref<string>(props.initialTab)
// const notice = ref('');
const signupSuccess = ref(false)
const uid = ref('')
const beauticianCode = ref('')
const outlet = ref<OutletType | null>(null)

const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 }
const outerCols = {
  span: 24,
  md: 12,
  lg: 12,
  xl: 12,
}

watch(() => props.initialTab, (val) => {
  tab.value = val
})

const signIn = () => {
  tab.value = 'signin'
  router.push('/login')
}

const showConfirmation = ref(false)
const okToNoOutlet = ref(false)
const handleConfirm = () => {
  console.log('Confirmed')
  showConfirmation.value = false
  okToNoOutlet.value = true
  signUp()
}

const handleCancel = () => {
  console.log('Cancelled')
  showConfirmation.value = false
}

const postSignup = async () => {
  try {
    loading.value = true
    const user = await dataFetchSingleWhere<UserType>(userModel, { uid: $acl.currentUser.uid })
    if (!user) {
      console.log('waiting user data...')
      setTimeout(() => {
        postSignup()
      }, 1000)
      // throw new Error('User not created yet');
      return
    }

    await callFunc('finalizeSignup', {
      firstName: name.value,
      lastName: '',
      teams: siteTeamIds,
      roles: [],
      noTeam: true,
      noRole: true,
      outletId: outlet.value?._id,
    })

    await $acl.refetchCurrentUser()
    // handle redirect if saved any
    if ($route.query?.redirect) {
      return router.push($route.query.redirect as string)
    }
    router.push('/')
  }
  catch (e: any) {
    console.error('signup error', e)
    if (e.error) {
      error.value = e.error.replace('name', 'Email')
      return
    }
    error.value = e.message
  }
  finally {
    loading.value = false
  }
}

const testTeam = async () => {
  signupSuccess.value = false
  error.value = ''
  loading.value = true
  const realm = getRealm()
  try {
    console.log('teams', siteTeamIds)
    const res = await callFunc('testTeam', { teams: siteTeamIds })
    console.log('res', res)
  }
  catch (e: any) {
    console.error('testTeam error', e)
    if (e.error) {
      error.value = e.error.replace('name', 'Email')
      return
    }
    error.value = e.message
  }
  finally {
    loading.value = false
  }
}

const fetchOutletByBeauticianCode = async (code: string) => {
  try {
    outlet.value = await dataFetchSingleWhere(outletModel, { code })
    return outlet.value
  } catch (error) {
    console.error('Error fetching outlet:', error)
    return null
  }
}

const signUp = async () => {
  signupSuccess.value = false
  error.value = ''
  if (!name.value || !email.value || !pass.value) {
    error.value = 'Please fill in the fields'
    return
  }
  if (!agreeTerm.value) {
    error.value = 'Please agree to our terms and privacy policy'
    return
  }
  if (!beauticianCode.value) {
    if (!okToNoOutlet.value) {
      showConfirmation.value = true
      return
    }
  }
  if (beauticianCode.value) {
    const outlet = await fetchOutletByBeauticianCode(beauticianCode.value)
    if (!outlet) {
      error.value = 'Invalid beautician code'
      return
    }
  }

  loading.value = true
  const realm = getRealm()
  try {
    const res = await realm.emailPasswordAuth.registerUser(
      { email: email.value, password: pass.value },
    )

    console.log('loggedin?', realm.currentUser, res)
    // notice.value = "Your account has been created"
    signupSuccess.value = true
    tab.value = 'signin'

    // automatic sign in
    const loginRes = await $acl.loginEmail(email.value, pass.value)
    postSignup()
  }
  catch (e: any) {
    console.error('signup error', e)
    if (e.error) {
      error.value = e.error.replace('name', 'Email')
      return
    }
    error.value = e.message
  }
  finally {
    loading.value = false
  }
}
</script>
<template>
  <div class="register">
    <!-- <a-button @click="testTeam">Test team</a-button> -->
    <a-row :gutter="rowGutter" type="flex">
      <a-col v-bind="outerCols">
        <div v-if="signupSuccess">
          <a-alert message="Your account has been created!" type="error" class="my-4" />
          <!-- <div class="my-4">
            Please check your e-mail to confirm your email...
          </div> -->
          <div class="flex mt-4 gap-2">
            <a-button class="flex-grow">Sign In</a-button>
          </div>
        </div>
        <a-spin :spinning="loading">
          <a-form v-if="tab === 'signin'" layout="vertical">
            <h1>Login</h1>
            <LoginComponent />
            <div class="flex mt-4 gap-2">
              <!-- <a-button type="primary" class="flex-grow" @click="signUp">Sign Up</a-button> -->

              <a-button class="flex-grow" @click="() => (tab = 'signup')">Sign Up</a-button>
            </div>
          </a-form>

          <a-form v-if="tab === 'signup' && !signupSuccess" layout="vertical">
            <h1>Create an account</h1>

            <!-- <div class="social text-center">
              <a-button type="link" size="large">
                <FacebookOutlined size="large" />
              </a-button>

              <a-button type="link" size="large">
                <GoogleOutlined />
              </a-button>
            </div> -->
            <ConfirmationDialog :show="showConfirmation" yes-label="Confirm" no-label="Cancel" @yes="handleConfirm"
              @no="handleCancel">
              <div class="p-4 text-center">
                <p class="text-lg mb-2">
                  <span class="font-semibold">Oops!</span> 😅 It looks like you haven't entered a
                  <span class="text-pink-500 font-bold italic">beautician code</span>, which could
                  qualify you for discount. 🎉
                </p>
                <p class="text-md mb-4">
                  Would you like to proceed without applying a code, or do you not have one? 🤔
                </p>
                <p class="text-sm">
                  If you need a code, feel free to
                  <span class=" font-semibold ">contact your beautician</span>
                  for assistance. 📞
                </p>
              </div>
            </ConfirmationDialog>

            <div class="my-4">or use your email for registration:</div>
            <a-form-item label="Name">
              <a-input v-model:value="name" />
            </a-form-item>
            <a-form-item label="Email">
              <a-input v-model:value="email" />
            </a-form-item>

            <a-form-item label="Password">
              <a-input v-model:value="pass" type="password" />
            </a-form-item>

            <a-form-item label="Beautician code">
              <a-input v-model:value="beauticianCode" />
            </a-form-item>

            <div class="mt-4">
              <a-checkbox v-model:checked="agreeTerm">I agree to the Terms and Privacy Policy.</a-checkbox>
            </div>

            <a-alert v-if="error" :message="error" type="error" class="my-4" />

            <div class="flex mt-4 gap-2">
              <a-button :disabled="loading" type="primary" class="flex-grow" @click="signUp">Sign Up</a-button>

              <a-button class="flex-grow" @click="signIn">Sign In</a-button>
            </div>
          </a-form>

          <a-form v-if="tab === 'reset'" layout="vertical">
            <h1>Reset</h1>
            <login-reset />
          </a-form>

        </a-spin>
      </a-col>
      <a-col v-bind="outerCols" class="hidden md:block">
        <img src="/img/register.jpg" class="w-full h-full absolute object-cover" />
      </a-col>
    </a-row>
  </div>
</template>

<style lang="scss" scoped>
.register {
  @apply bg-white p-6;
  min-height: 450px;
  margin: 2em auto;
  width: 700px;
  max-width: 100%;
}
</style>
